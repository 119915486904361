<template>
  <v-card>
    <v-card-title class="text-h6 mb-3">
      {{ $t("labels.priority_goods_quantity") }}
      <v-spacer></v-spacer>

      <v-btn
        class="mr-2"
        color="success"
        outlined
        small
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>

      <v-btn color="error" small outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="2">
          <select-warehouse-model
            v-model="item.id_warehouse"
            :single-line="false"
            :label="$t('labels.warehouse_1')"
          ></select-warehouse-model>
        </v-col>

        <v-col cols="2">
          <v-autocomplete
            v-model="item.id_e_marketplace"
            :items="sourceOptions"
            :label="$t('labels.e_market')"
            :placeholder="$t('labels.e_market')"
            :disabled="false"
            class="c-input-small"
            dense
            outlined
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-autocomplete
            v-model="item.id_customer_e_marketplace_shop"
            :items="shopOptions"
            :label="$t('labels.shop')"
            :placeholder="$t('labels.shop')"
            :disabled="false"
            class="c-input-small"
            dense
            outlined
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <date-time-picker
            v-model="item.start_time"
            :label="$t('labels.start_time')"
            :placeholder="$t('labels.start_time')"
            :single-line="false"
            :min-date="minDate"
          ></date-time-picker>
        </v-col>

        <v-col cols="2">
          <date-time-picker
            v-model="item.end_time"
            :label="$t('labels.end_time')"
            :placeholder="$t('labels.end_time')"
            :single-line="false"
            :min-date="minDate"
          ></date-time-picker>
        </v-col>

        <v-col cols="2">
          <select-multiple-model
            v-model="item.id_ignore_warehouses"
            :label="$t('labels.ignore_warehouse')"
            :placeholder="$t('labels.ignore_warehouse')"
            :options="ignoreWarehouseOptions"
            :single-line="false"
          ></select-multiple-model>
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="codes"
            class="c-input-small input-disabled-bold"
            :label="$t('labels.barcode')"
            :placeholder="$t('labels.config_sync_stock_placeholder')"
            outlined
            dense
            hide-details
            rows="3"
            @paste.prevent.stop="onBarcodePaste"
            @input="getGoods"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-simple-table
        fixed-header
        height="calc(100vh - 345px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">
                {{ $t("labels.image") }}
              </th>
              <th class="text-center">
                {{ $t("labels.sku") }}
              </th>
              <th class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th class="text-center">
                {{ $t("labels.goods_name") }}
              </th>
              <th class="text-center">
                {{ $t("labels.goods_description") }}
              </th>
              <th class="text-center">
                {{ $t("labels.size") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in items"
              :key="`pgq_${key}`"
              class="text-center"
            >
              <td>
                <ImageViewer
                  :url="
                    item.url_images || require('@/assets/common/no-image.jpg')
                  "
                  width="40px"
                  height="40px"
                />
              </td>
              <td>{{ item.sku }}</td>
              <td>{{ item.customer_goods_barcode }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment/moment";
import { httpClient } from "@/libs/http";
import { E_MARKETS } from "@/libs/const";

export default {
  name: "PriorityGoodsQuantityCreate",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    updateItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    codes: "",
    item: {
      id_warehouse: null,
      id_e_marketplace: null,
      id_customer_e_marketplace_shop: null,
      start_time: null,
      end_time: null,
      id_ignore_warehouses: [],
    },
    isLoading: false,
    eMarkets: [...E_MARKETS],
    shops: [],
  }),
  computed: {
    minDate() {
      return moment().add(1, "minutes").format("YYYY-MM-DD");
    },
    isDisabledBtn() {
      if (
        !this.items ||
        this.items.length === 0 ||
        !this.item.id_warehouse ||
        !this.item.id_e_marketplace ||
        !this.item.id_customer_e_marketplace_shop ||
        !this.item.start_time ||
        !this.item.end_time ||
        !this.item.id_ignore_warehouses ||
        this.item.id_ignore_warehouses.length === 0
      ) {
        return true;
      }

      const now = moment().add(1, "minutes").format("YYYY-MM-DD HH:mm:ss");
      if (
        now > this.item.start_time ||
        this.item.start_time > this.item.end_time
      ) {
        return true;
      }

      return false;
    },
    sourceOptions() {
      if (!this.item.id_warehouse) {
        return [];
      }

      const warehouseEMarkets = [];
      const warehouseShops = [...this.shops].filter(
        (shop) => shop.id_warehouse == this.item.id_warehouse
      );

      for (let i = 0; i < warehouseShops.length; i++) {
        const shop = warehouseShops[i];
        if (!warehouseEMarkets.includes(shop.id_e_marketplace)) {
          warehouseEMarkets.push(shop.id_e_marketplace);
        }
      }

      const allowEMarkets = [...this.eMarkets].filter((em) =>
        warehouseEMarkets.includes(em.value)
      );
      return allowEMarkets;
    },
    shopOptions() {
      if (!this.item.id_warehouse || !this.item.id_e_marketplace) {
        return [];
      }

      return [...this.shops]
        .filter(
          (shop) =>
            shop.id_e_marketplace == this.item.id_e_marketplace &&
            shop.id_warehouse == this.item.id_warehouse
        )
        .map((shop) => ({
          text: shop.display_name,
          value: shop.id,
        }));
    },
    ignoreWarehouseOptions() {
      if (
        !this.item.id_customer_e_marketplace_shop ||
        !this.item.id_warehouse
      ) {
        return [];
      }
      const shopWarehouses = [...this.shops].filter(
        (shop) =>
          shop.id == this.item.id_customer_e_marketplace_shop &&
          shop.id_warehouse != this.item.id_warehouse
      );

      return [...shopWarehouses].map((sw) => ({
        text: sw.warehouse_code,
        value: sw.id_warehouse,
      }));
    },
  },
  mounted() {
    this.getShops();
  },
  methods: {
    close() {
      this.$emit("close");
    },

    onBarcodePaste(evt) {
      const value = evt.clipboardData.getData("text");
      if (value.includes("\n")) {
        const codeSplit = value.split("\n").filter((v) => v);
        this.codes = codeSplit.join(";");
      } else if (value.includes(" ")) {
        const codeSplit = value.split(" ").filter((v) => v);
        this.codes = codeSplit.join(";");
      } else {
        this.codes += value;
      }
      this.getGoods();
    },

    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/priority-goods-quantity-save", {
          ...this.item,
          items: this.items,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData");
        this.close();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async getShops() {
      const { data } = await httpClient.post("/list-e-market-shop", {
        active: 1,
      });
      this.shops = [...data];
    },

    async getGoods() {
      const barcodes = this.codes.split(";");

      const { data } = await httpClient.post("/get-goods-by-barcodes", {
        barcodes,
      });

      this.items = [...data];
    },
  },
};
</script>

<style scoped></style>
